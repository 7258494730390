<template>
  <div>
    <section
      class="card h-100"
      itemscope
      itemprop="mainEntity"
      itemtype="https://schema.org/Question"
    >
      <h6 class="bg-transparent border-0 card-header pb-0" itemprop="name">
        <b>{{ faq.title }}</b>
      </h6>
      <div
        itemscope
        itemprop="acceptedAnswer"
        itemtype="https://schema.org/Answer"
      >
        <div class="card-body pb-0" itemprop="text" v-html="faq.text"></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: ["faq"],
  data: function() {
    return {
      collapse: true
    };
  },
  methods: {
    collapseToggle() {
      this.collapse = !this.collapse;
    }
  }
};
</script>

<style lang="scss" scoped></style>
