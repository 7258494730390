<template>
  <div itemscope itemtype="http://schema.org/FAQPage">
    <h5 class="text-center mb-3 col-md-12"><b>FAQ</b></h5>
    <Faq
      v-for="(faq, index) in faqs"
      :faq="faq"
      :key="index"
      class="col-md-6 mb-md-3"
      :class="{ 'mb-3': index != faqs.length - 1 }"
    />
  </div>
</template>

<script>
import Faq from "@/components/Faq.vue";

export default {
  data() {
    return {
      faqs: []
    };
  },
  components: {
    Faq
  },
  methods: {
    getFaqs() {
      this.$http({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}faqs?network=`
      }).then(
        result => {
          let faqs = result.data.faqs;
          this.faqs = faqs;
        },
        error => {
          console.error(error);
        }
      );
    }
  },
  beforeMount() {
    this.getFaqs();
  }
};
</script>

<style lang="scss" scoped></style>
