<template>
  <select
    class="custom-select custom-select-lg"
    v-model="selectedItem"
    @change="updateParent"
  >
    <option value="null" hidden disabled selected>Select a country</option>
    <optgroup v-if="optgroup" :label="label">
      <option :value="[optgroup]">{{ optgroup.CountryName }}</option>
    </optgroup>
    <optgroup label="All Locations">
      <option :value="[item]" v-for="item in items" :key="item.CountryIso">{{
        item.CountryName
      }}</option>
    </optgroup>
  </select>
</template>

<script>
export default {
  props: ["items", "optgroup", "label"],
  data() {
    return {
      selectedItem: [this.optgroup]
    };
  },
  methods: {
    updateParent() {
      this.$emit("change", this.selectedItem);
    }
  }
};
</script>
