<template>
  <div>
    <img src="/img/topup/background@2x.png" alt="Topups.com Lifestyle" class="page-bg w-100 img-fluid">
    <div class="container container-sm mb-3">
      <div class="card mb-4">
        <div class="card-body">
          <h2 class="h4 mb-4 text-center font-weight-bold">
            Topups.com provides topups for over {{ countries.length }} countries
          </h2>
          <div class="row">
            <div
              v-for="(country,index) in countries"
              :key="country.CountryIso"
              class="col-sm-6 col-md-4"
              :class="{'mb-3': index != countries.length - 1}"
            >
              <router-link
                :to="'/topup/' + country.CountryName.replace(/\s/g, '-').toLowerCase()"
                class="card text-reset h-100"
                role="button"
              >
                <div class="row no-gutters align-items-center h-100">
                  <div class="col-auto">
                    <div class="card-img img-thumbnail border-0 text-center p-2">
                      <img
                        :src="
                          'https://imagerepo.ding.com/flag/' +
                            country.CountryIso +
                            '.svg'
                        "
                        width="30"
                      />
                    </div>
                  </div>
                  <div class="col h-100">
                    <div
                      class="card-footer h-100 border-top-0 d-flex align-items-center"
                    >
                      <strong> {{ country.CountryName }} </strong>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      countries: []
    };
  },
  beforeMount() {
    this.countries = this.$route.params.countries;
  },
  head: {
    title: {
      inner: 'Top-up Worldwide in over 200 Countries',
      separator: '|',
      complement: 'Topups.Com'
    },
    meta: [
      { n: 'description', c: 'Top-up online by selecting a country below then choose a mobile network and top-up using our speedy phone recharge service.', id: 'desc' }, // id to replace intead of create element
      // Twitter
      { n: 'twitter:title', c: 'Top-up Worldwide in over 200 Countries | Topups.Com' },
      { n: 'twitter:description', c: 'Top-up online by selecting a country below then choose a mobile network and top-up using our speedy phone recharge service.'},
      // Google+ / Schema.org
      { ip: 'name', c: 'Top-up Worldwide in over 200 Countries | Topups.Com' },
      { ip: 'description', c: 'Top-up online by selecting a country below then choose a mobile network and top-up using our speedy phone recharge service.' },
      // Facebook / Open Graph
      // { p: 'fb:app_id', c: '123456789' },
      { p: 'og:title', c: 'Top-up Worldwide in over 200 Countries | Topups.Com' },
      // { p: 'og:image', c: 'https://example.com/image.jpg' },
    ],
    link: function() {
      return [
        {
          rel: "canonical",
          href: "https://www.topups.com/topup/countries",
          id: "canonical"
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.card-img {
  max-height: 100px;
  object-fit: contain;
}
.details {
  left: 0;
  bottom: 0;
  opacity: 1;
  background-color: rgba(#000000, 0.5);
  transition: all 0.2s ease-in-out;
  > div {
    transform: translateY(0);
    border-radius: 1rem 1rem 0 0;
    transition: transform 0.2s ease-in-out;
  }
  &:not(.show) {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    > div {
      transform: translateY(100%);
    }
  }
}
</style>
