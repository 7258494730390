<template>
  <div>
    <img src="/img/topup/background@2x.png" alt="Topups.com Lifestyle" class="page-bg w-100 img-fluid">
    <div class="container container-sm">
      <div class="card mb-4">
        <div class="card-body">
          <div class="text-center mb-3">
            <h1 class="card-title h5 font-weight-bold">
              Fastest Mobile Top-up Online
            </h1>
            <h2 class="card-subtitle h6 font-weight-bold">
              Buy mobile phone credit &amp; data
            </h2>
          </div>
          <div class="form-group">
            <label for="country">Receiver's country:</label>
            <div class="input-group mb-3" v-if="country">
              <div class="input-group-prepend">
                <label class="input-group-text bg-transparent" for="country"
                  ><img
                    :src="
                      'https://imagerepo.ding.com/flag/' +
                        country.CountryIso +
                        '.png?height=16&compress=none'
                    "
                    :alt="country.CountryName"
                /></label>
              </div>
              <CountrySelect
                :items="countries"
                label="Your Location"
                :optgroup="country"
                id="country"
                @change="updateCountry($event[0])"
                class="border-left-0"
              />
            </div>
          </div>
          <router-link
            :to="{
              name: 'Networks',
              params: { iso: country.CountryIso.toLowerCase(), country: country.CountryName.replace(/\s/g, '-').toLowerCase() }
            }"
            class="btn btn-block btn-warning border-dark mb-3"
            v-if="country"
            ><b>Start top-up</b></router-link
          >

          <!-- TrustBox widget - Micro Review Count -->
          <div
            v-if="$env != 'development'"
            id="trustbox"
            class="trustpilot-widget mb-3"
            data-locale="en-GB"
            data-template-id="5419b6a8b0d04a076446a9ad"
            data-businessunit-id="5bf2cf3030ff6e00011c54f6"
            data-style-height="24px"
            data-style-width="100%"
            data-theme="light"
          >
            <a
              href="https://uk.trustpilot.com/review/topups.com"
              target="_blank"
              >Trustpilot</a
            >
          </div>
          <!-- End TrustBox widget -->

          <div class="d-flex justify-content-center">
            <div class="col-auto px-2">
              <img
                src="/img/topup/mastercard@2x.png"
                alt="Mastercard"
                class="img-fluid"
                width="86"
              />
            </div>
            <div class="col-auto px-2">
              <img
                src="/img/topup/visa@2x.png"
                alt="Visa"
                class="img-fluid"
                width="66"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex no-gutters text-sm-center">
        <div class="col-8 col-sm-12">
          <h3 class="h6 font-weight-bold mb-3 text-primary">
            Fast &amp; Secure
          </h3>
          <ul class="list-unstyled d-sm-flex justify-content-sm-center mb-5">
            <li class="mb-1 mx-sm-2">
              <font-awesome-icon :icon="['fas', 'bolt']" fixed-width /> Fast
              digital delivery
            </li>
            <li class="mb-1 mx-sm-2">
              <font-awesome-icon :icon="['fas', 'lock']" fixed-width /> Pay safe
              &amp; secure online
            </li>
            <li class="mb-1 mx-sm-2">
              <font-awesome-icon :icon="['fas', 'heart']" fixed-width /> Loved
              by millions
            </li>
          </ul>
        </div>
        <div class="col-4 d-flex d-sm-none">
          <img
            src="/img/topup/fast-secure@2x.png"
            alt="Fast &amp; Secure"
            class="img-fluid"
            style="object-fit: cover;"
          />
        </div>
      </div>
    </div>

    <div class="bg-secondary mb-md-4 py-3">
      <div class="container container-sm">
        <h4 class="h5 text-center"><b>Stay Connected With Topups.com</b></h4>
        <p>
          We’re on a mission to change mobile top-ups online, making it the quick
          and easy option for staying in touch with friends &amp; family.
        </p>
        <p>
          The one-stop-shop for topping up, we'll send credit to an account or
          mobile phone top-up pin in less than 60 seconds.
        </p>
        <Faqs class="form-row" />
      </div>
    </div>
  </div>
</template>

<script>
import CountrySelect from "@/components/Select.vue";
import Faqs from "@/components/Faqs.vue";

import { mapGetters } from "vuex";

export default {
  name: "Topup",
  components: {
    CountrySelect,
    Faqs
  },
  data() {
    return {
      countries: [],
      loading: false
    };
  },
  head: {
    title: {
      inner: 'Mobile Top-up Online | Recharge Phone Here',
      separator: '|',
      complement: 'Topups.Com'
    },
    meta: [
      { n: 'description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.', id: 'desc' }, // id to replace intead of create element
      // Twitter
      { n: 'twitter:title', c: 'Mobile Top-up Online | Recharge Phone Here | Topups.Com' },
      { n: 'twitter:description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.'},
      // Google+ / Schema.org
      { ip: 'name', c: 'Mobile Top-up Online | Recharge Phone Here | Topups.Com' },
      { ip: 'description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.' },
      // Facebook / Open Graph
      // { p: 'fb:app_id', c: '123456789' },
      { p: 'og:title', c: 'Mobile Top-up Online | Recharge Phone Here | Topups.Com' },
      // { p: 'og:image', c: 'https://example.com/image.jpg' },
    ],
    link: function() {
      return [
        {
          rel: "canonical",
          href: "https://www.topups.com" + this.$route.path,
          id: "canonical"
        }
      ];
    }
  },
  beforeMount() {
    this.locationData();
    
    if (this.$env != "development") {
      const trustbox = document.getElementById("trustbox");
      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(trustbox);
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      order: "order",
      country: "country"
    })
  },
  methods: {
    locationData() {
      this.loading = true;
      this.$http({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}countries`
      }).then(
        result => {
          const networks = result.data.kyc.networks;

          if (networks && networks.length) {
            this.networks = networks;
          }

          this.countries = result.data.countries;

          if (this.country.CountryIso == null) {
            this.$store.dispatch("setCountry", result.data.kyc.country);
          }

          this.user.registration = {
            ip: result.data.kyc.ip,
            country: result.data.kyc.country.CountryName,
            status: "incomplete"
          };

          this.$store.dispatch("fetchUser", this.user);
        },
        error => {
          console.error(error);
        }
      );
    },
    updateCountry(country) {
      this.$store.dispatch("setCountry", country);
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  border-width: 0.15em;
}
</style>
